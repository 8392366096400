import Storage from '@/support/Storage';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { Report as ReportModel, Media } from '@/models/Report';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { File as FileModel } from '@/models/File';
import draggable from 'vuedraggable';
import AttachmentDialog from '@/views/Reports/attachment-dialog/AttachmentDialog.vue';
import { Options } from '@/components/mi-dialog/MiDialog';

@Component<Aanzichtfotos>({
  components: {
    draggable,
    AttachmentDialog,
  },
})
export default class Aanzichtfotos extends Vue {
  public $pageTitle = 'Aanzichtfotos';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  @Prop({ default: true })
  protected allowEdit!: boolean;

  @Prop({ default: false })
  protected isEditor!: boolean;

  @Prop({ default: false })
  protected disabled!: boolean;

  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected isEditingPicture = false;

  protected isCreatingPicture = false;

  protected pictures: Media[] = [];

  protected selectedPicture: Media | null = null;

  protected dragOptions: any = {
    animation: 200,
    disabled: this.disabled,
    ghostClass: 'ghost',
  };

  protected fileDescription = '';

  protected mounted(): void {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.isLoading = true;

    await this.loadPictures();

    this.isLoading = false;
  }

  protected async loadPictures() {
    const response = await this.report.listMedia({
      type: 'pictures',
    })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (response.data) {
      this.pictures = response.data;
    }
  }

  protected updateMediaOrder() {
    let fileIds: string[];

    fileIds = this.pictures.map((file: Media) => file.uuid as string);

    if (! fileIds.length) {
      return;
    }

    new FileModel()
      .sortFiles(fileIds)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected editPicture(media: Media) {
    this.selectedPicture = media;
    this.fileDescription = media.description || '';
    this.isEditingPicture = true;
  }

  protected deletePicture(attachment: Media) {
    this.selectedPicture = attachment;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteImage);
  }

  protected get dialogOptionsDeleteImage(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze bijlage wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          color: 'warning',
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            if (! this.report || ! this.selectedPicture) {
              return;
            }

            this.report
              .deleteMediaEndpoint(this.selectedPicture.uuid ? this.selectedPicture.uuid : '')
              .then((response: AxiosResponse) => {
                this.loadPictures();
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedPicture = null;
          },
        },
      },
    };
  }

  protected postFile(file: File | string | string[]) {
    if (! file) {
      return;
    }

    const payload = {
      description: this.fileDescription,
      file,
      type: 'picture',
    };

    new ReportModel()
      .mediaEndpoint(this.report.uuid ? this.report.uuid : '')
      .create(payload)
      .then((response: AxiosResponse) => {
        this.loadPictures();
        this.isEditingPicture = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected navigateToExpertEditor() {
    Storage.set('expert_activeTab', 'tab-bijlagen');
    this.$router.push(`/expert/${this.report.uuid}`);
  }
}
